<template>
	<v-container v-if="$can('view', 'Page')" fluid>
		<v-card v-if="!loading">
			<div class="py-4">
				<div class="pb-1 mb-4 header">
					<div
						class="
							px-8
							d-flex
							flex-row
							align-start
							justify-space-between
						"
					>
						<h1 class="crud-title">Contact Us</h1>
					</div>
				</div>

				<div class="px-8">
					<FileUploader
						:initFiles="banner ? [banner] : []"
						@errorOccured="dropzoneError"
						:purpose="`banner`"
						:maxFiles="1"
						@changed="fileUploaderChanged"
					/>

					<p class="mb-3 section-heading">Heading</p>
					<v-text-field
						v-model="heading"
						placeholder="Enter Title for the page"
						dense
						outlined
						solo
						flat
					></v-text-field>

					<p class="mb-3 section-heading">Sub Heading</p>
					<v-text-field
						v-model="subHeading"
						placeholder="Enter Caption"
						dense
						outlined
						solo
						flat
					></v-text-field>

					<ContactUs
						@on-contacts-update="updateAddresses($event)"
						:initial-data="addresses"
						:showFileUploader="true"
					></ContactUs>
					<UnoSaveButton
						@click.native="saveData()"
						@primary-button-click="dashboard()"
						primary-button-text="Move To Dashboard"
						secondary-button-text="Continue Editing"
						:alert="alert"
						:saving="isSaving"
					></UnoSaveButton>
				</div>
			</div>
		</v-card>
	</v-container>
</template>

<script>
import ContactUs from '@/domains/SubsectionLayout/components/ContactUs'
import FileUploader from '@/components/FileUploader'
import UnoSaveButton from '@/components/Buttons/UnoSaveButton'

export default {
	data() {
		return {
			heading: '',
			subHeading: '',
			addresses: [],
			banner: null,
			bannerId: null,
			loading: true,
			alert: {
				show: null,
				message: null,
				error: false,
			},
			isSaving: false,
		}
	},
	components: {
		ContactUs,
		FileUploader,
		UnoSaveButton,
	},
	created() {
		this.getInitialData()
	},
	methods: {
		updateAddresses(addresses) {
			this.addresses = addresses
			// this.saveAddresses()
		},
		saveData() {
			const projectId = this.$store.state.app.projectId
			let addresses = this.addresses.map((address) => ({
				heading: address.heading,
				emails: address.emails,
				phones: address.phones,
				address: address.address,
				mapLink: address.mapLink,
				image: address.image ? address.image.id : null,
			}))
			let payload = {
				addresses,
				heading: this.heading,
				subHeading: this.subHeading,
				banner: this.bannerId,
			}
			this.axios({
				method: 'patch',
				url: `/${projectId}/pages/contact`,
				data: payload,
			})
				.then((res) => {
					if (res.status === 404) {
						console.log(404)
					}

					if (res.status == 200 && res.data.success) {
						this.alert.message = res.data.message
						this.setData(res.data.data.meta)
					}
				})
				.catch((error) => {
					const { data } = error.response
					if (data.statusCode == 400) {
						this.$snackbar.notify({
							message: data.message,
							color: 'black',
						})
					}
				})
		},
		getInitialData() {
			const projectId = this.$store.state.app.projectId
			this.axios
				.get(`/${projectId}/pages/contact`)
				.then((res) => {
					this.loading = false
					const data = res.data.meta
					this.setData(data)
				})
				.catch(() => {
					this.loading = false
				})
		},
		setData(data) {
			this.heading = data.heading
			this.subHeading = data.subHeading ? data.subHeading : null
			this.addresses = data.addresses.map((address) => {
				if (address.image && address.image.media) {
					address.image = {
						id: address.image.media,
						publicURL: address.image.publicURL,
					}
				} else {
					address.image = null
				}

				return address
			})
			this.banner = data.banner && data.banner.media ? data.banner : null
			this.bannerId =
				data.banner && data.banner.media ? data.banner.media : null
		},

		fileUploaderChanged(val) {
			if (val.length != 0) {
				const data = val[0]
				this.bannerId = data.id
				this.purpose = 'banner'
				this.banner = {
					id: this.bannerId,
					publicURL: data.publicURL,
				}
			}
		},
		dropzoneError(val) {
			console.log(val)
		},
	},
}
</script>

<style scoped>
.header {
	border-bottom: 1px solid #eee;
}
</style>
