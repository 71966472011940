<template>
	<div>
		<v-tooltip bottom>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					v-bind="attrs"
					v-on="on"
					color="#f44336"
					class="save-btn mr-4 pa-3"
					text
					:loading="saving"
					@click="showModal = true"
				>
					Save<span class="mdi mdi-check pl-2 icon"></span>
				</v-btn>
			</template>
			<span class="tooltip-text">{{ tooltipText }}</span>
		</v-tooltip>

		<v-dialog
			v-model="showModal"
			max-width="600px"
			v-if="alert.message !== null"
		>
			<v-card class="pa-6 mb-0 d-flex flex-column success-modal">
				<div class="d-flex flex-row align-center">
					<span
						class="mdi mdi-checkbox-marked-circle icon pr-2"
					></span>
					<h1 class="heading">
						{{ alert.message }}
					</h1>
				</div>
				<div class="d-flex flex-row justify-end">
					<v-btn
						class="mt-4 align-self-end mr-3"
						outlined
						color="#4daf51"
						@click="$emit('primary-button-click')"
						>{{ primaryButtonText }}</v-btn
					>
					<v-btn
						class="mt-4"
						outlined
						color="indigo"
						@click="showModal = false"
						>{{ secondaryButtonText }}</v-btn
					>
				</div>
			</v-card>
		</v-dialog>
		<!-- <v-dialog v-model="showModal" max-width="600px">
			<v-card class="pa-6 mb-0 d-flex flex-column success-modal">
				<div class="d-flex flex-row align-center">
					<span class="mdi mr-2"></span>
					<h1 class="heading">{{ confirmationText }}</h1>
				</div>
				<div class="d-flex flex-row justify-end">
					<v-btn
						class="mt-4 mr-4"
						outlined
						color="green darken-1"
						@click="confirmSave()"
						>Save</v-btn
					>
					<v-btn
						class="mt-4"
						outlined
						color="indigo"
						@click="cancel()"
						>Cancel</v-btn
					>
				</div>
			</v-card>
		</v-dialog> -->
	</div>
</template>

<script>
export default {
	props: {
		alert: {
			type: Object,
			required: false,
			default: () => {
				return { message: null }
			},
		},
		tooltipText: {
			type: String,
			default: 'Save',
		},
		primaryButtonText: {
			type: String,
			required: true,
		},
		secondaryButtonText: {
			type: String,
			required: true,
		},
		saving: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			showModal: false,
		}
	},
	methods: {
		// askConfirmation() {
		// 	this.showModal = true
		// },
		// confirmSave() {
		// 	this.$emit('confirm')
		// },
		// cancel() {
		// 	this.showModal = false
		// },
	},
}
</script>
<style scoped>
.save-btn {
	color: white !important;
	background-color: var(--blue) !important;
	padding: 0 16px !important;
}
.heading {
	color: green;
	font-weight: 300;
}
.success-modal .heading {
	color: #4daf51;
	font-weight: 400;
	font-size: 1.5rem;
}
.success-modal .icon {
	font-size: 1.7rem;
	color: #4daf51;
}
</style>
