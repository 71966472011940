<template>
	<v-navigation-drawer
		v-model="drawer"
		fixed
		stateless
		temporary
		right
		class="link-drawer"
	>
		<div class="d-flex align-center header pt-4 px-2 pb-2">
			<span
				class="mdi mdi-chevron-left back-icon"
				@click="closeDrawer()"
			></span>
			<h1 class="crud-title text-center">Contact Information</h1>
			<span class="mdi mdi-close back-icon" @click="closeDrawer()"></span>
		</div>
		<div class="pa-4">
			<div v-if="showFileUploader">
				<v-radio-group v-model="fileType" row>
					<v-radio
						label="File Uploader"
						value="imageUploaded"
						@click=";(embeddedMapLink = ''), (mapLink = '')"
					></v-radio>
					<v-radio
						label="Add Address for Google Map Link"
						value="mapLink"
						@click="banner = ''"
					></v-radio>
				</v-radio-group>
				<FileUploader
					v-if="fileType === 'imageUploaded'"
					:initFiles="banner ? [banner] : []"
					@errorOccured="dropzoneError"
					:purpose="`Address banner`"
					:maxFiles="1"
					@changed="fileUploaderChanged"
				/>
				<div v-else>
					<v-text-field
						class="pa-0"
						v-model="embeddedMapLink"
						outlined
						dense
						placeholder="Enter your Business Name"
						@change="getMapLinkSrc()"
						:error-messages="errors.embeddedMapLink"
					></v-text-field>
					<iframe
						v-if="mapLink !== null"
						:src="mapLink"
						frameborder="0"
						width="100%"
						height="300px"
					></iframe>
				</div>
			</div>
			<div
				class="d-flex align-center justify-space-between mb-3"
				v-if="showHeader"
			>
				<h1 class="heading">Section Heading</h1>
			</div>
			<v-text-field
				v-if="showHeader"
				class="pa-0"
				v-model="heading"
				outlined
				dense
				placeholder="Write the name for this section of Website's Footer."
				:error-messages="errors.heading"
			></v-text-field>
			<h1 class="heading mb-3">Address</h1>
			<v-textarea
				solo
				flat
				name="address"
				label="Enter the Address"
				no-resize
				outlined
				v-model="address"
				:error-messages="errors.address"
			></v-textarea>
			<h1 class="heading mb-3">Email</h1>
			<v-text-field
				v-model="email"
				outlined
				dense
				@keyup.enter="updateEmail()"
				placeholder="Enter your Emails here"
			></v-text-field>
			<div v-if="showChip">
				<v-chip
					v-for="email in emails"
					:key="email.uuid"
					class="mb-2 mr-1"
					close
					outlined
					dense
					@click:close="removeEmail(email.uuid)"
				>
					{{ email.email }}
				</v-chip>
			</div>
			<h1 class="heading my-3">Phone Number</h1>

			<v-text-field
				outlined
				dense
				v-model="phone"
				@keyup.enter="updateContact()"
				placeholder="Enter your Phone Numbers here"
			></v-text-field>
			<div v-if="showChip">
				<v-chip
					outlined
					dense
					v-for="phone in phones"
					:key="phone.uuid"
					class="mb-2 mr-1"
					close
					@click:close="removePhone(phone.uuid)"
				>
					{{ phone.number }}
				</v-chip>
			</div>
		</div>
		<v-footer class="footer pa-0">
			<v-btn @click="saveContact()" depressed class="save"
				>Done</v-btn
			></v-footer
		>
	</v-navigation-drawer>
</template>
<script>
import { v4 as uuidv4 } from 'uuid'
import FileUploader from '@/components/FileUploader'

export default {
	components: {
		FileUploader,
	},
	props: {
		initialData: {
			type: Object,
			required: false,
			default: () => {
				return {}
			},
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showFileUploader: {
			type: Boolean,
			default: false,
		},
	},
	created() {
		if (Object.keys(this.initialData).length > 0) {
			this.heading = this.initialData.heading
			this.address = this.initialData.address
			this.emails = this.initialData.emails.map((email) => ({
				email: email,
				uuid: uuidv4(),
			}))
			this.phones = this.initialData.phones.map((phone) => ({
				number: phone,
				uuid: uuidv4(),
			}))
			this.uuid = this.initialData.uuid
			this.showChip = true
			this.banner = this.initialData.image ? this.initialData.image : null
			this.bannerId = this.initialData.image
				? this.initialData.image.id
				: null
			this.mapLink = this.initialData.mapLink
				? this.initialData.mapLink
				: null
			this.initialData.mapLink !== null
				? (this.fileType = 'mapLink')
				: (this.fileType = 'imageUploaded')
			this.initialData.mapLink !== null
				? (this.embeddedMapLink = `<iframe src=${this.mapLink}></iframe>`)
				: (this.embeddedMapLink = null)
		}
	},
	data() {
		return {
			drawer: true,
			heading: '',
			address: '',
			phones: [],
			emails: [],
			uuid: '',
			banner: null,
			bannerId: null,
			email: '',
			showChip: false,
			phone: null,
			emailRules: [(v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
			errors: {
				heading: '',
				address: '',
				embeddedMapLink: '',
			},
			fileType: 'imageUploaded',
			embeddedMapLink: null,
			mapLink: null,
		}
	},
	watch: {
		heading() {
			this.errors.heading = ''
		},
		address() {
			this.errors.address = ''
		},
		embeddedMapLink() {
			this.errors.embeddedMapLink = ''
		},
	},
	methods: {
		saveContact() {
			let isValid = true
			if (this.heading === '') {
				isValid = false
				this.errors.heading = 'Please enter a heading'
			} else {
				this.errors.heading = ''
			}
			if (this.address === '') {
				isValid = false
				this.errors.address = 'Please Enter address'
			} else {
				this.errors.address = ''
			}
			if (isValid) {
				let contact = {
					heading: this.heading,
					address: this.address,
					emails: this.emails.map((email) => {
						return email['email']
					}),
					phones: this.phones.map((phone) => {
						return phone['number']
					}),
					uuid: this.uuid,
				}
				if (this.showFileUploader) {
					contact.image = this.banner
					contact.mapLink = this.mapLink
				}

				this.$emit('on-contact-update', contact)
			}
		},
		updateEmail() {
			let obj = {
				email: this.email,
				uuid: uuidv4(),
			}
			this.emails.push(obj)
			this.showChip = true
			this.email = ''
		},
		updateContact() {
			let obj = {
				number: this.phone,
				uuid: uuidv4(),
			}
			this.phones.push(obj)
			this.showChip = true
			this.phone = null
		},
		removeEmail(id) {
			let emails = this.emails
			emails.every(function (email, index) {
				if (email.uuid === id) {
					emails.splice(index, 1)
					return false // to break the loop
				} else {
					return true
				}
			})
			this.emails = emails
		},
		removePhone(id) {
			let phones = this.phones
			phones.every(function (phone, index) {
				if (phone.uuid === id) {
					phones.splice(index, 1)
					return false // to break the loop
				} else {
					return true
				}
			})
			this.phones = phones
		},
		closeDrawer() {
			let contact = {
				heading: this.heading,
				address: this.address,
				emails: this.emails,
				phones: this.phones,
				uuid: this.uuid,
			}
			this.$emit('close-drawer', contact)
		},
		fileUploaderChanged(val) {
			if (val.length != 0) {
				const data = val[0]
				this.bannerId = data.id
				this.purpose = 'banner'
				this.banner = {
					id: this.bannerId,
					publicURL: data.publicURL,
				}
			}
		},
		dropzoneError(val) {
			console.log(val)
		},
		getMapLinkSrc() {
			let data = this.embeddedMapLink
			var parser = new DOMParser()
			var parsedIframe = parser.parseFromString(data, 'text/html')
			let iFrame = parsedIframe.getElementsByTagName('iframe')

			// Read URL:
			if (iFrame.length > 0) {
				this.mapLink = iFrame[0].src
			} else {
				this.errors.embeddedMapLink =
					'Please Enter a Valid Embedded Map Link.'
			}
		},
	},
}
</script>
<style scoped>
.add {
	background-color: var(--blue) !important;
	color: white;
}
.path {
	color: grey;
}
.heading {
	font-size: 1.2rem;
	font-weight: 400;
}
.links {
	border: 1px solid #eee;
	padding: 0px 12px 10px 12px;
	padding-bottom: 10px;
}
.remove {
	font-size: 1.5rem;
	color: #f44336;
	cursor: pointer;
}
.link-drawer {
	width: 40% !important;
	height: 100% !important;
	overflow-y: auto;
}
.link-drawer .header {
	border-bottom: 1px solid #eee;
}
.link-drawer .back-icon {
	font-size: 25px;
	cursor: pointer;
}
.link-drawer .crud-title {
	width: 100%;
}
.link-drawer .close-icon {
	font-size: 22px;
	cursor: pointer;
}
.errors {
	color: red;
	font-size: 13px;
}
.footer {
	background-color: var(--blue);
	height: 60px;
}
.save {
	background-color: var(--blue) !important;
	color: white;
	width: 100% !important;
	height: 100% !important;
}
</style>
