<template>
	<div>
		<div class="d-flex align-end justify-space-between mb-5">
			<p class="section-heading">Add Contact Information</p>
			<v-btn depressed class="add" @click="addContact()">
				+ Add Section
			</v-btn>
		</div>
		<draggable
			v-model="contacts"
			class="drag-area"
			ghost-class="ghost"
			@change="emitData()"
		>
			<transition-group name="sortable">
				<v-card
					v-for="contact in contacts"
					:key="contact.uuid"
					:class="[
						'contact-card',
						(contact.image && contact.image.publicURL) ||
						(contact.mapLink && contact.mapLink !== null)
							? 'contact-card-height'
							: '',
					]"
				>
					<v-row class="mx-0 address">
						<v-col
							cols="4"
							v-if="
								(contact.image && contact.image.publicURL) ||
								(contact.mapLink && contact.mapLink !== null)
							"
							class="pa-0 image-container"
						>
							<v-img
								:src="contact.image.publicURL"
								height="100%"
								v-if="contact.image && contact.image.publicURL"
							>
							</v-img>
							<iframe
								:src="contact.mapLink"
								frameborder="0"
								width="100%"
								height="100%"
							></iframe>
						</v-col>
						<v-col
							:cols="
								(contact.image && contact.image.publicURL) ||
								(contact.mapLink && contact.mapLink !== null)
									? '8'
									: '12'
							"
						>
							<div
								class="d-flex align-start justify-space-between"
							>
								<h1 class="section-heading font-weight-medium">
									{{ contact.heading }}
								</h1>
								<div class="d-flex flex-row ml-2">
									<span
										class="mdi mdi-pencil edit mr-2"
										@click="editContact(contact)"
									></span>
									<span
										class="mdi mdi-close-circle remove"
										@click="removeContact(contact.uuid)"
									></span>
								</div>
							</div>

							<p>
								{{ contact.address }}
							</p>
							<div
								class="d-flex flex-row align-center"
								v-if="contact.emails.length > 0"
							>
								<span class="mdi mdi-email mail-icon"> </span>
								<div
									v-for="(
										emailAddress, index
									) in contact.emails"
									:key="index"
									class="email"
								>
									{{ emailAddress + ' ' }}
									<span class="separator">,</span>
								</div>
							</div>
							<div
								class="d-flex flex-row align-center"
								v-if="contact.phones.length > 0"
							>
								<span class="mdi mdi-phone phone-icon"></span>
								<div
									v-for="(phone, index) in contact.phones"
									:key="index"
									class="phone"
								>
									{{ phone + ' ' }}
									<span class="separator">,</span>
								</div>
							</div>
						</v-col>
					</v-row>
				</v-card>
			</transition-group>
		</draggable>
		<div v-if="showContactUsDrawer">
			<ContactUsSectionCard
				v-for="contact in contacts"
				:key="contact.uuid"
				@close-drawer="closeDrawer($event)"
				@on-contact-update="updateContacts($event)"
				:initial-data="contactEdit"
				:showFileUploader="showFileUploader"
			></ContactUsSectionCard>
		</div>
	</div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid'

import ContactUsSectionCard from './ContactUsSectionCard'

import draggable from 'vuedraggable'
export default {
	components: {
		ContactUsSectionCard,
		draggable,
	},
	props: {
		initialData: {
			type: Array,
		},
		showFileUploader: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		initialData() {
			if (this.showFileUploader) {
				this.contacts = this.initialData.map((contact) => ({
					address: contact.address,
					emails: contact.emails,
					heading: contact.heading,
					phones: contact.phones,
					uuid: uuidv4(),
					image: contact.image,
					mapLink: contact.mapLink,
				}))
			} else {
				this.contacts = this.initialData.map((contact) => ({
					address: contact.address,
					emails: contact.emails,
					heading: contact.heading,
					phones: contact.phones,
					uuid: uuidv4(),
				}))
			}
		},
	},
	data() {
		return {
			contacts: [],
			contactEdit: {},
			showContactUsDrawer: false,
		}
	},
	methods: {
		closeDrawer(emittedObj) {
			this.showContactUsDrawer = !this.showContactUsDrawer
			if (emittedObj.heading === '') {
				this.removeContact(emittedObj.uuid)
			}
		},
		addContact() {
			this.showContactUsDrawer = !this.showContactUsDrawer
			let obj = {
				heading: '',
				emails: [],
				phones: [],
				address: '',
				uuid: uuidv4(),
			}
			if (this.showFileUploader) {
				obj.image = null
				obj.mapLink = null
			}
			let arr = this.contacts
			arr.push(obj)
			this.contacts = arr
			this.contactEdit = obj
		},
		updateContacts(emittedObj) {
			let contacts = this.contacts
			let showFileUploader = this.showFileUploader
			contacts.every(function (contact, index) {
				if (contact.uuid === emittedObj.uuid) {
					let obj = {
						heading: emittedObj.heading,
						emails: emittedObj.emails,
						phones: emittedObj.phones,
						address: emittedObj.address,
						uuid: emittedObj.uuid,
					}
					if (showFileUploader) {
						obj.image = emittedObj.image ? emittedObj.image : null
						obj.mapLink = emittedObj.mapLink
							? emittedObj.mapLink
							: null
					}
					contacts[index] = obj
					// vm.$set(vm.facilities, index, obj);
					return false // to break the loop
				} else {
					return true
				}
			})
			this.contacts = contacts
			this.showContactUsDrawer = false
			this.emitData()
		},
		removeContact(contactId) {
			let contacts = this.contacts

			contacts.forEach((contact, index) => {
				if (contact.uuid === contactId) {
					contacts.splice(index, 1)
					return false // to break the loop
				} else {
					return true
				}
			})
			this.contacts = contacts
			this.emitData()
		},
		editContact(contact) {
			this.showContactUsDrawer = !this.showContactUsDrawer
			this.contactEdit = contact
		},
		emitData() {
			let obj = {}
			if (this.showFileUploader) {
				obj = this.contacts.map((contact) => ({
					heading: contact.heading,
					emails: contact.emails,
					phones: contact.phones,
					address: contact.address,
					image: contact.image,
					mapLink: contact.mapLink,
				}))
			} else {
				obj = this.contacts.map((contact) => ({
					heading: contact.heading,
					emails: contact.emails,
					phones: contact.phones,
					address: contact.address,
				}))
			}
			this.$emit('on-contacts-update', obj)
		},
	},
}
</script>
<style scoped>
.add {
	background-color: var(--blue) !important;
	color: white;
}
.section-heading {
	font-size: 1.2rem;
}
.email:last-child .separator {
	display: none !important;
}
.phone:last-child .separator {
	display: none !important;
}
.mail-icon,
.phone-icon {
	font-size: 22px;
	margin-right: 10px;
}
.contact-card {
	cursor: all-scroll;
}
.contact-card-height {
	height: 250px;
	overflow: hidden;
}
.remove {
	font-size: 1.5rem;
	color: #f44336;
	cursor: pointer;
}
.edit {
	font-size: 1.3rem;
	color: var(--blue);
	cursor: pointer;
}
.sortable-move {
	transition: transform 1s;
}
.address {
	height: 100%;
}
.image-container {
	height: 100%;
	display: flex;
	align-items: center;
}
</style>
